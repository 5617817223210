// js
import 'prismjs'
import './node_modules/prismjs/plugins/autolinker/prism-autolinker.js'
import './node_modules/prismjs/plugins/normalize-whitespace/prism-normalize-whitespace.js'

// css
import './node_modules/knacss/css/knacss.css'
import './css/styles.css'
import './node_modules/prismjs/themes/prism-funky.css'

/* import './node_modules/prismjs/plugins/autolinker/prism-autolinker.css' */

// img
import './favicon.ico'

// pdf
import './doc/*.pdf'

//
console.log('Salut, on fait connaissance :) ?')
